
<template>
  <BackofficeBase :loader_prop="loader_prop">

    <DistributionPointMenu :point_id_prod="point_id_prod" />
    
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu"

export default {
  name: 'BackofficeDistributionPoint',
  components: {
    BackofficeBase,
    DistributionPointMenu
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      point_id_prod : 0
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_distribution_point", {"id": this.id})
    console.log(view_data.data.distribution_point_data)
    this.view_data = view_data.data.distribution_point_data
    this.loader_prop = false
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

